<script setup>
import {ref} from "vue"
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton, OneInput} from "@jirizavadil/framework.one/ui"
import {firestore} from "@/firebase/index.js"
import {addDoc, collection, serverTimestamp} from "firebase/firestore"
import sink from "@/sink/index.js"

const props = defineProps({
  section: Object,
})

const progressSubmit = ref(false)
const fieldStatus = ref({
  email: false,
  socialURL: false,
})
const form = ref({
  audience: "creator",
  email: null,
  socialURL: null,
})

const stage = ref("init")

async function submit() {
  progressSubmit.value = true

  // validate email
  if (!form.value.email?.trim()) {
    fieldStatus.value.email = "error"
    progressSubmit.value = false
  }

  if (!form.value.socialURL?.trim()) {
    fieldStatus.value.socialURL = "error"
    progressSubmit.value = false
    return
  }

  const collectionRef = collection(firestore, `waitlist`)
  const data = {
    ...form.value,
    meta: {
      created: serverTimestamp(),
      updated: false,
      deleted: false,
    },
  }
  await addDoc(collectionRef, data).catch((e) => {
    console.error(e)
  })
  stage.value = "sent"
  progressSubmit.value = false
}
</script>

<template>
  <LayoutContainer class="w-full">
    <div class="relative overflow-hidden rounded-3xl bg-sand-200 px-4 py-5 md:px-8 md:py-8">
      <div
        class="absolute inset-0 z-20 flex items-center bg-sand-200 px-8"
        v-show="stage == 'sent'"
      >
        <div>
          <h2 class="text-3xl font-bold text-brown-700">{{ section.fields.formSentTitle }}</h2>
          <p class="mt-2 text-xl text-brown-600">{{ section.fields.formSentParagraph }}</p>
        </div>
      </div>

      <h2 class="text-3xl font-bold text-brown-700">{{ section.fields.title }}</h2>
      <p class="mt-2 text-xl text-brown-600">{{ section.fields.paragraph }}</p>

      <div class="max-w-sm">
        <!-- email -->
        <OneInput
          class="mt-4"
          :size="14"
          :label="section.fields.formFieldEmailLabel"
          labelInside
          fullWidth
          :placeholder="section.fields.formFieldEmailPlaceholder"
          v-model="form.email"
          :status="fieldStatus.email"
          @blur="sink.event('WaitlistEmailFill', {audience})"
          @enter="submit"
        />

        <!-- social URL -->
        <OneInput
          class="mt-6"
          :size="14"
          :label="section.fields.formFieldSocialUrlLabel"
          labelInside
          fullWidth
          :placeholder="section.fields.formFieldSocialUrlPlaceholder"
          v-model="form.socialURL"
          :status="fieldStatus.socialURL"
          @blur="sink.event('WaitlistSocialURLFill', {audience})"
          @keyup="fieldStatus.socialURL = false"
          @enter="submit"
        />
      </div>

      <OneButton
        variant="primaryViolet"
        :progress="progressSubmit"
        :size="14"
        class="mt-6"
        :label="section.fields.formSubmitLabel"
        @click="submit"
      />
    </div>
  </LayoutContainer>
</template>
