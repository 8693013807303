<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {CheckCircleIcon} from "@heroicons/vue/24/solid"
import {OneButton} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  cms: Object,
  section: Object,
})

function highlight(text) {
  return text.replace(/\*([^*]+)\*/g, '<span class="text-violet-500">$1</span>')
}
</script>

<template>
  <div class="">
    <LayoutContainer>
      <h3 class="text-4xl font-semibold text-brown-700">{{ section.fields.title }}</h3>
      <p class="mt-2 max-w-xl text-lg text-brown-600">{{ section.fields.description }}</p>
    </LayoutContainer>

    <!-- plans -->
    <div class="mt-8 overflow-x-scroll xl:mx-auto xl:w-full xl:max-w-5xl xl:px-4 xl:pr-0">
      <!-- plan descriptions -->
      <div class="ml-4 inline-flex flex-row gap-4 xl:ml-0 xl:grid xl:grid-cols-3">
        <p
          class="w-[616px] whitespace-nowrap rounded-full border-2 border-sand-200 px-4 py-2 text-brown-600 xl:col-span-2 xl:w-auto"
        >
          {{ section.fields.subtitleDestinations }}
        </p>
        <p
          class="w-[300px] whitespace-nowrap rounded-full border-2 border-sand-200 px-4 py-2 text-brown-600 xl:w-auto"
        >
          {{ section.fields.subtitleEnterprise }}
        </p>
      </div>

      <!-- plan boxes -->
      <div class="ml-4 mt-4 flex flex-row gap-4 xl:ml-0 xl:grid xl:grid-cols-3">
        <div
          class="flex w-[300px] shrink-0 flex-col overflow-hidden rounded-3xl bg-sand-100 xl:w-auto"
          v-for="plan in section.fields.plans"
          :key="plan.id"
        >
          <!-- head -->
          <div class="relative bg-sand-200 p-4 pt-16">
            <!-- badge -->
            <span
              class="absolute right-4 top-4 rounded-full bg-brown-700 px-3.5 py-1.5 text-xs font-semibold uppercase text-brown-200"
              v-if="plan.fields.badge"
              >{{ plan.fields.badge }}</span
            >

            <!-- title -->
            <span class="text-3xl font-semibold text-neutral-700">{{ plan.fields.title }}</span>
            <!-- price -->
            <div class="mt-1 flex flex-row items-end gap-3">
              <span class="text-4xl font-bold text-brown-600">{{ plan.fields.price }}</span>
              <div class="flex flex-col gap-0">
                <span class="text-xl text-brown-600">{{ plan.fields.priceLabelLarge }}</span>
                <span class="-mt-0.5 text-xs font-semibold uppercase text-brown-400">{{
                  plan.fields.priceLabelSmall
                }}</span>
              </div>
            </div>
          </div>

          <!-- features & CTA -->
          <div class="flex grow flex-col justify-between pt-2">
            <!-- features -->
            <div class="flex flex-col gap-3 p-4">
              <div
                class="flex flex-row items-center gap-2"
                v-for="(feature, index) in plan.fields.features"
                :key="index"
              >
                <CheckCircleIcon class="size-5 text-violet-500" />
                <span class="text-brown-700" v-html="highlight(feature)" />
              </div>
            </div>

            <!-- CTA -->
            <div class="p-4">
              <OneButton
                :label="plan.fields.cta.title"
                :size="10"
                variant="primaryViolet"
                :kind="plan.fields.ctaPrimary ? 'solid' : 'ghost'"
                tag="a"
                :href="plan.fields.cta.value"
                :target="plan.fields.cta.target"
                @action="sink.event('SectionPlansCTAClick', {cta: plan.fields.cta})"
              />
            </div>
          </div>
        </div>
        <div class="block h-full w-4">&nbsp;</div>
      </div>
    </div>
  </div>
</template>
