<script setup>
import {provide, ref, computed, watch} from "vue"
import {RouterView} from "vue-router"
import {useRoute} from "vue-router"

// this is consumed by ImageCDN in framework.one
const urlCDN = ref(null)
provide("FRAMEWORK_ONE_URL_CDN_IMAGES", urlCDN)

const route = useRoute()

const cmsData = ref(null)

const cmsPage = computed(() => {
  if (!cmsData.value) return
  return cmsData.value.items.find((item) => {
    // return item.meta?.model === "page" && item.fields?.slug === route.meta.contentKey
    return item.fields?.slug === route.meta.contentKey
  })
})

// sections that are not 'page'
const cmsGlobal = computed(() => {
  if (!cmsData.value) return
  // const sectionDirections = cmsData.value.items.find(
  //   (item) => item.meta?.model === "sectionDirections"
  // )
  // const faqCategories = cmsData.value.items.filter((item) => item.meta?.model === "faqCategory")
  // const faqs = cmsData.value.items.filter((item) => item.meta?.model === "faq")
  return {
    // sectionDirections,
    // faqCategories,
    // faqs,
  }
})

const cms = computed(() => {
  return {
    ...(cmsPage.value && {page: cmsPage.value}),
    ...(cmsGlobal.value && {global: cmsGlobal.value}),
  }
})

// fetch content
async function fetchContent() {
  const url = `https://static.platforma.one/orgs/atlaseek/spaces/hub.atlaseek.com/environments/main/locales/en-us/site.json`
  const response = await fetch(url, {
    method: "GET",
    cache: "no-cache", // TODO: remove this?
  })
  const data = await response.json()
  cmsData.value = data

  if (data?.cdn?.images) {
    urlCDN.value = data.cdn.images
    // console.log("provided", data.cdn.images)
  }
  console.log(`generated: ${new Date(data.generated)}`)
}

fetchContent()
</script>

<template>
  <div class="">
    <RouterView :cms v-if="Object.keys(cms).length" />
  </div>
</template>
