import LandingView from "../views/LandingView.vue"

export const routes = [
  {
    path: "/",
    name: "LandingView",
    component: LandingView,
    meta: {
      contentKey: "landing",
      requiresAuth: false,
    },
  },
  {
    path: "/creator",
    name: "CreatorView",
    component: () => import("../views/CreatorView.vue"),
    meta: {
      contentKey: "creator",
      requiresAuth: false,
    },
  },
  {
    path: "/terms",
    name: "TermsView",
    component: () => import("../views/TermsView.vue"),
    meta: {
      contentKey: "terms",
      requiresAuth: false,
    },
  },
  {path: "", redirect: {name: "LandingView"}},
  {path: "/:pathMatch(.*)*", redirect: {name: "LandingView"}},
]
