<script setup>
import {computed} from "vue"
import LayoutContainer from "../layout/LayoutContainer.vue"
import IconInstagram from "../../assets/img/icons/platforms/icon-instagram.svg"
import IconAtlaseek from "../../assets/img/icons/platforms/icon-atlaseek-square.svg"
import IconLinkedin from "../../assets/img/icons/platforms/icon-linkedin.svg"
import sink from "@/sink/index.js"

const props = defineProps({
  cms: Object,
  section: Object,
})

function parseIcons(text) {
  // Split text by potential icon insertions
  const parts = text.split(/\[icon\|([^\]]+)\]/)
  return parts.map((part) => {
    switch (part) {
      case "instagram":
        return {
          type: "component",
          kind: "instagram",
          href: "https://instagram.com/atlaseek",
          component: IconInstagram,
        }
      case "atlaseek":
        return {
          type: "component",
          kind: "atlaseek",
          href: "https://atlaseek.com/blog",
          component: IconAtlaseek,
        }
      case "linkedin":
        return {
          type: "component",
          kind: "linkedin",
          href: "https://www.linkedin.com/company/atlaseek",
          component: IconLinkedin,
        }
      default:
        return {type: "text", content: part}
    }
  })
}

const line1Parsed = computed(() => parseIcons(props.section.fields.line1))
const line2Parsed = computed(() => parseIcons(props.section.fields.line2))
const line3Parsed = computed(() => parseIcons(props.section.fields.line3))
</script>

<template>
  <LayoutContainer>
    <template v-for="(part, index) in line1Parsed" :key="'line1-' + index">
      <span
        class="text-4xl leading-[2.8rem] text-brown-700"
        v-html="part.content"
        v-if="part.type === 'text'"
      />
      <a
        class="inline-block"
        v-else-if="part.type === 'component'"
        :href="part.href"
        target="_blank"
        @click="sink.event('SectionSocialsIconClick', {icon: part.kind})"
      >
        <component
          :is="part.component"
          class="-ml-1 inline-block size-12 -translate-y-2 text-brown-300 transition-all duration-300 ease-in-out hover:text-brown-500"
        />
      </a>
    </template>
    <div class="-mt-2">
      <template v-for="(part, index) in line2Parsed" :key="'line2-' + index">
        <span
          class="text-4xl leading-[2.8rem] text-brown-700"
          v-html="part.content"
          v-if="part.type === 'text'"
        />
        <a
          class="inline-block"
          v-else-if="part.type === 'component'"
          :href="part.href"
          target="_blank"
          @click="sink.event('SectionSocialsIconClick', {icon: part.kind})"
        >
          <component
            :is="part.component"
            class="-ml-1 inline-block size-12 -translate-y-2 text-brown-300 transition-all duration-300 ease-in-out hover:text-brown-500"
          />
        </a>
      </template>
      <div class="-mt-2">
        <template v-for="(part, index) in line3Parsed" :key="'line3-' + index">
          <span
            class="whitespace-nowrap text-4xl leading-[2.8rem] text-brown-700"
            v-html="part.content"
            v-if="part.type === 'text'"
          />
          <a
            class="inline-block"
            v-else-if="part.type === 'component'"
            :href="part.href"
            target="_blank"
            @click="sink.event('SectionSocialsIconClick', {icon: part.kind})"
          >
            <component
              :is="part.component"
              class="-ml-1 inline-block size-12 -translate-y-2 text-brown-300 transition-all duration-300 ease-in-out hover:text-brown-500"
            />
          </a>
        </template>
      </div>
    </div>
  </LayoutContainer>
</template>
