<script setup>
import {ref, onMounted, onUnmounted, computed} from "vue"
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import {ArrowRightCircleIcon} from "@heroicons/vue/24/solid"
import IconAtlaseek from "../../assets/img/icons/platforms/icon-atlaseek-square.svg"
import IconInstagram from "../../assets/img/icons/platforms/icon-instagram.svg"
import IconYoutube from "../../assets/img/icons/platforms/icon-youtube.svg"
import IconTiktok from "../../assets/img/icons/platforms/icon-tiktok-square.svg"
import sink from "@/sink/index.js"

const props = defineProps({
  cms: Object,
  section: Object,
})

const highlights = ["hotel", "destination", "tour", "brand", "attraction"]

const currentHighlight = ref("destination")
const speed = 2000
let intervalId

function highlight(text) {
  return text.replace(/\*(.*?)\*/g, "<b>$1</b>")
}

onMounted(() => {
  let index = highlights.indexOf(currentHighlight.value)
  intervalId = setInterval(() => {
    index = (index + 1) % highlights.length // Cycle through the highlights
    currentHighlight.value = highlights[index]
  }, speed) // Update every second
})

onUnmounted(() => {
  clearInterval(intervalId) // Clear the interval when the component unmounts
})
</script>

<template>
  <LayoutContainer>
    <div class="flex flex-col items-start gap-0">
      <!-- news pill -->
      <a
        class="mb-10 inline-flex flex-row gap-2 rounded-full bg-brown-300 py-1 pl-1 pr-3 text-sand-50"
        :href="section.fields.newsPill.value"
        :target="section.fields.newsPill.target"
        @click="sink.event('SectionHeroLandingNewsPillClick', {newsPill: section.fields.newsPill})"
        v-if="section.fields.newsPill?.value && section.fields.newsPill?.title"
      >
        <ArrowRightCircleIcon class="size-6" /><span class="">{{
          section.fields.newsPill.title
        }}</span>
      </a>

      <!-- title -->
      <!--<h2 class="text-4xl font-semibold text-brown-800">{{ section.fields.title }}</h2>-->

      <h2
        class="text-[2.25rem] font-semibold leading-[2.8rem] -tracking-[0.06rem] text-brown-800 md:text-[4.5rem] md:leading-[4.5rem] lg:text-[5rem] lg:leading-[5rem]"
      >
        Make&nbsp;your&nbsp;<span class="relative inline overflow-hidden text-white">
          {{ currentHighlight }}<br />
          <transition-group name="fade" tag="div" class="inline">
            <div
              v-for="highlight in highlights"
              :key="highlight"
              v-show="highlight === currentHighlight"
              class="masked-image absolute -bottom-2 -left-1 -right-3 -top-6 -z-10 inline-block h-auto w-[190px] md:-left-3 md:-top-16 md:w-[430px]"
            >
              <img
                class="cover absolute inset-0 h-auto w-full"
                :src="`/img/hero/${highlight}.jpg`"
                alt="Masked Image"
              />
            </div>
          </transition-group>
        </span>
        visible&nbsp;through the&nbsp;most&nbsp;<span class="relative"
          >influential
          <!-- platforms -->
          <div
            class="absolute -top-2 left-0 -z-10 flex flex-row -space-x-1 md:left-20 md:top-6 md:scale-[160%] md:space-x-4"
          >
            <IconAtlaseek class="h-auto w-11 translate-y-3 -rotate-[7deg] text-brown-200" />
            <IconTiktok class="h-auto w-9 translate-y-5 rotate-[15deg] text-brown-200" />
            <IconInstagram
              class="h-auto w-14 -translate-y-6 translate-x-2 -rotate-[14deg] text-brown-200"
            />
            <IconYoutube
              class="h-auto w-[4.5rem] translate-y-5 rotate-[8deg] text-brown-200"
            /></div
        ></span>
        travel&nbsp;community
      </h2>

      <!-- creators -->
      <div class="mt-1 flex flex-row -space-x-4 md:mt-2 md:-space-x-6">
        <img
          class="size-12 rounded-full border-2 border-sand-50 md:size-20 md:border-4"
          src="/img/creators/creator-01.jpg"
        />
        <img
          class="size-12 rounded-full border-2 border-sand-50 md:size-20 md:border-4"
          src="/img/creators/creator-02.jpg"
        />
        <img
          class="size-12 rounded-full border-2 border-sand-50 md:size-20 md:border-4"
          src="/img/creators/creator-03.jpg"
        />
      </div>

      <!-- subtitle -->
      <p
        class="mt-6 text-lg text-brown-600 md:mt-8 md:max-w-3xl md:text-2xl"
        v-html="highlight(section.fields.subtitle)"
      />

      <OneButton
        variant="primaryViolet"
        class="mt-10 md:hidden"
        :label="section.fields.cta.title"
        tag="a"
        :href="section.fields.cta.value"
        :target="section.fields.cta.target"
        :size="12"
        @action="sink.event('SectionHeroLandingCTAClick', {cta: section.fields.cta})"
      />

      <OneButton
        variant="primaryViolet"
        class="mt-10 hidden md:flex"
        :label="section.fields.cta.title"
        tag="a"
        :href="section.fields.cta.value"
        :target="section.fields.cta.target"
        :size="16"
        @action="sink.event('SectionHeroLandingCTAClick', {cta: section.fields.cta})"
      />
    </div>
  </LayoutContainer>
</template>

<style>
.masked-image img {
  -webkit-mask-image: url("/img/hero-mask-destination.svg");
  mask-image: url("/img/hero-mask-destination.svg");
  -webkit-mask-size: contain;
  mask-size: contain;
  -webkit-mask-repeat: no-repeat;
  mask-repeat: no-repeat;
}

/* Transition classes for fade effect */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}
</style>
