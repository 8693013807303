<script setup>
import LayoutContainer from "../layout/LayoutContainer.vue"
import {OIcon} from "@jirizavadil/framework.one/ui"
import sink from "@/sink/index.js"

defineProps({
  cms: Object,
  section: Object,
})

function mailto(email) {
  // add 'mailto:' if not present
  if (!email.includes("mailto:")) {
    return `mailto:${email}`
  } else {
    return email
  }
}
</script>

<template>
  <LayoutContainer>
    <!-- email -->
    <a
      :href="mailto(section.fields.email.value)"
      :target="section.fields.email.target"
      class="inline-block text-4xl text-violet-500 transition-all duration-300 ease-in-out hover:underline"
      @click="sink.event('SectionFooterEmailClick')"
      >{{ section.fields.email.title }}</a
    >

    <div class="mb-4 mt-32 flex flex-col md:flex-row-reverse md:items-end md:justify-between">
      <!-- claim -->
      <span class="text-xl text-violet-400">{{ section.fields.claim }}</span>

      <!-- terms -->
      <RouterLink
        :to="{name: section.fields.terms.value}"
        :target="section.fields.terms.target"
        class="mt-6 inline-block text-sm text-brown-300"
        @click="sink.event('SectionFooterTermsClick')"
        >{{ section.fields.terms.title }}</RouterLink
      >
    </div>
  </LayoutContainer>
</template>
