<script setup>
import Logo from "../assets/img/logo/atlaseek_hub_temp.svg"
import LayoutContainer from "./layout/LayoutContainer.vue"
import {OneButton} from "@jirizavadil/framework.one/ui"
import {useRoute, useRouter} from "vue-router"
import sink from "@/sink/index.js"

const route = useRoute()
const router = useRouter()

function onClickCreator() {
  sink.event("HeaderCreatorClick")
  router.push({name: "CreatorView"})
}
</script>

<template>
  <LayoutContainer>
    <div class="flex h-14 flex-row items-center justify-between pt-6 md:h-24">
      <a class="text-2xl font-bold md:text-4xl" href="/"><Logo class="h-auto w-40 md:w-64" /></a>

      <OneButton
        variant="primaryViolet"
        class="md:hidden"
        label="I'm a creator"
        kind="ghost"
        :size="8"
        @action="onClickCreator"
        v-if="route.name === 'LandingView'"
      />
      <OneButton
        variant="primaryViolet"
        class="hidden md:block"
        label="I'm a creator"
        kind="ghost"
        :size="12"
        @action="onClickCreator"
        v-if="route.name === 'LandingView'"
      />
    </div>
  </LayoutContainer>
</template>
